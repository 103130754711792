@import 'globalStyles/colors';
@import 'globalStyles/mixins/svg-text';

.rectangle {
  fill: $doublingCubeBackgroundColor;
}

.text {
  @include svg-text(1.2rem, $doublingCubeColor, 'Roboto Condensed');
}

.rectangle-animated {
  animation: pulsating 0.77s infinite normal;
}

@keyframes pulsating {
  0% {
    fill: $doublingCubeBackgroundColor;
  }

  50% {
    fill: $doublingCubePulsatingBackgroundColor;
  }

  100% {
    fill: $doublingCubeBackgroundColor;
  }
}
