$white-lighter-1: rgb(255, 255, 255);
$white: rgb(232, 234, 255);
$white-semi-opaque: rgba(232, 234, 255, 0.5);
$white-darker-1: rgb(230, 231, 238);
$gray: rgb(230, 232, 247);
$gray-lighter-1: rgb(242, 245, 255);
$gray-lighter-2: rgb(235, 237, 250);
$gray-lighter-3: rgb(218, 221, 245);
$gray-lighter-4: rgb(213, 219, 255);
$gray-lighter-5: rgb(200, 204, 247);
$gray-darker-1: rgb(209, 209, 231);
$gray-darker-2: rgb(169, 165, 192);
$gray-darker-3: rgb(202, 202, 202);
$gray-darker-4: rgb(197, 197, 197);
$gray-darker-5: rgb(194, 194, 195);
$gray-darker-6: rgb(170, 172, 174);
$gray-darker-7: rgb(159, 162, 165);
$gray-darker-8: rgb(187, 187, 187);
$blue: rgb(46, 44, 173);
$purple: rgb(51, 8, 115);
$purple-opacity: rgba(39, 6, 95, 0.4);
$purple-darker-1: rgb(38, 6, 95);
$purple-darker-2: rgb(9, 1, 55);
$purple-darker-3: rgb(7, 0, 36);
$purple-darker-4: rgb(7, 2, 64);
$purple-lighter-1: rgb(144, 144, 214);
$purple-lighter-2: rgb(65, 12, 149);
$purple-lighter-3: rgb(95, 16, 189);
$blue-lighter-1: rgb(43, 68, 255);
$blue-lighter-2: rgb(62, 123, 227);
$blue-lighter-3: rgb(18, 46, 255);
$blue-lighter-4: rgb(63, 82, 176);
$blue-darker-1: rgb(56, 44, 173);
$blue-darker-2: rgb(37, 62, 143);
$blue-darker-3: rgb(46, 40, 134);
$blue-darker-4: rgb(31, 29, 144);
$red-opacity: rgba(224, 37, 0, 0.7);
$red: rgb(222, 37, 1);
$red-lighter-1: rgb(231, 76, 60);
$red-lighter-2: rgb(233, 105, 105);
$red-lighter-3: rgb(250, 218, 237);
$magenta: rgb(210, 33, 116);
$green: rgb(116, 197, 116);
$green-darker-1: rgb(100, 181, 100);
$brown: rgb(171, 104, 0);
$yellow-darker-1: rgb(235, 163, 0);
$yellow: rgb(242, 179, 0);
$yellow-lighter-1: rgb(245, 184, 0);
$yellow-lighter-2: rgb(254, 202, 59);
$orange-lighter-1: rgb(245, 90, 0);
$orange: rgb(217, 150, 0);
$orange-darker-1: rgb(210, 140, 0);
$black: rgb(0, 0, 0);
$red: rgb(187, 15, 15);
$pink: rgb(255, 0, 203);

// board-specific colors
$purple-board: rgb(46, 44, 173);
$purple-darker-1-board: rgb(7, 0, 36);
$purple-lighter-1-board: rgb(65, 12, 149);
$blue-darker-2-board: rgb(62, 123, 227);
$blue-darker-3-board: rgb(43, 68, 255);
$white-board: rgb(218, 221, 245);
$blue-darker-4-board: rgb(18, 25, 74);
$evenPointColor: rgb(55, 106, 215);
$oddPointColor: rgb(103, 151, 223);

$sectionNavbarBackgroundTopGradient: $gray;
$sectionNavbarBackgroundBottomGradient: $gray-darker-1;
$serverNotificationBarBackgroundTopGradient: $gray-darker-1;
$serverNotificationBarBackgroundBottomGradient: $white-darker-1;
$serverNotificationLegalTermsColor: $blue-darker-1;
$serverNotificationLegalTermsBorderColor: $gray-darker-6;
$playNavbarActiveBackgroundColor: $gray-lighter-2;
$playNavbarMainTextColor: $blue;
$playNavbarSecondaryTextColor: $purple-lighter-1;
$timeColor: $white;
$timeBackgroundColor: $gray-darker-6;
$playNavbarCreateGameColor: $white;
$playNavbarCreateGameBackgroundColor: $blue-lighter-1;
$playNavbarFilterInactiveButtonColor: $blue-lighter-1;
$playNavbarFilterInactiveButtonBackgroundColor: $blue-lighter-1;
$playNavbarFilterButtonColor: $white;
$playNavbarFilterButtonBackgroundColor: $green-darker-1;
$playContentTableColor: $blue-darker-1;
$playContentTableTopGradient1: $gray-lighter-2;
$playContentTableTopGradient2: $gray-lighter-1;
$playContentTableBottomGradient: $gray;
$playContentTableBorderColor: $gray-lighter-2;
$playContentJoinButtonColor: $white;
$playContentJoinButtonBackgroundColor: $blue-lighter-1;
$playContentReconnectButtonColor: $white;
$playContentReconnectButtonBackgroundColor: $blue-lighter-1;
$playContentCancelButtonColor: $blue-lighter-1;
$playContentCancelButtonBorderColor: $blue-lighter-1;
$playContentViewButtonColor: $blue-lighter-1;
$playContentViewButtonBorderColor: $blue-lighter-1;
$playContentColor: $blue-darker-1;
$playListHeaderBackgroundColor: $gray-lighter-3;
$playListContentBackgroundColor: $white-lighter-1;
$playListLengthBackgroundColor: $white-lighter-1;
$playListMatchLightColorFrom: #f2ecd9;
$playListMatchLightColorTo: #ded0a0;

$headerSloganColor: $white;
$headerBackgroundTopGradient: $purple-darker-2;
$headerBackgroundBottomGradient: $purple;
$headerLinkColor: $white;
$headerLinkMobileBackgroundColor: $blue-lighter-1;
$headerLinkDesktopBackgroundColor: transparent;
$headerLinkMobileBorderColor: transparentize($purple-darker-3, 0.6);
$headerForgotPasswordColor: $purple-lighter-1;
$headerLoginButtonColor: $white;
$headerLoginButtonBackgroundColor: $blue-lighter-1;
$headerSignUpButtonColor: $white;
$headerSignUpButtonBackgroundColor: $orange-lighter-1;
$headerUserInfoColor: $white;
$headerUserInfoDescriptionColor: $white;
$headerLogOutButtonColor: $white;
$headerLogOutButtonBackground: $blue-lighter-1;
$headerUserInfoBackgroundColor: $blue-darker-2;
$headerMenuButtonColor: $white;
$headerMenuButtonBackgroundColor: $blue-lighter-1;

$signupTextColor: $white;
$signupBorderColor: $white;
$signupCheckboxBackgroundColor: $white;
$signupCheckboxCheckedBackgroundColor: $blue-darker-1;
$signupLinkTextColor: $purple-lighter-1;
$signupButtonBackgroundColor: $blue-lighter-1;
$signupButtonColor: $white;
$modalBackdropBackgroundColor: $blue-lighter-1;
$signupSignInColor: $white;
$errorColor: $red-lighter-1;

$inputPlaceHolderColor: $gray-darker-2;
$inputColor: $blue-darker-1;

$createGameBackgroundTopGradient: $purple-darker-3;
$createGameBackgroundBottomGradient: $purple-lighter-2;
$createGameTextColor: $gray-lighter-2;
$createGameClearButtonColor: $gray-lighter-2;
$createGameClearButtonBackgroundColor: $red;
$createGameDarkTextColor: $purple-darker-3;
$createGameSubmitBackgroundColor: $blue-lighter-1;

$gameProposalBackgroundTopGradient: $purple-darker-3;
$gameProposalBackgroundBottomGradient: $purple-lighter-2;
$gameProposalTextColor: $white;
$gameProposalDarkTextColor: $purple-darker-3;
$gameProposalButtonBackgroundColor: $blue-lighter-1;
$gameProposalDeactivateColor: $red;
$gameProposalDeactivateBackgroundColor: $red;

$gameProposalPlayerAColor: $white;
$gameProposalPlayerBColor: $blue;
$gameProposalRatingColor: $blue-lighter-2;
$gameProposalDetailsBackgroundColor: $blue-darker-3;
$gameProposalBubbleBackgroundColor: $white;
$gameProposalBubbleTextColor: $purple-darker-2;
$accountProfileBackground: $gray-lighter-2;

$findPlayerResultsCloseButton: $white;
$findPlayerResultsBackgroundClose: $blue-lighter-1;
$playerProfileTextColor: $white;
$userSettingsContentBackgroundColor: $gray-lighter-2;
$userSettingsAvatarBorderColor: $blue;
$userSettingsBorderColor: $gray-lighter-2;
$userSettingsTextColor: $blue;
$userSettingsFormControlDisabledColor: $gray-darker-2;
$userSettingsFormControlBackgroundColor: $gray-lighter-2;
$userSettingsErrorFormControlBackgroundColor: $red-lighter-3;
$userSettingsMoreAvatarsButtonBackgroundColor: $blue-lighter-1;
$userSettingsMoreAvatarsButtonColor: $white;
$userSettingsBorderColor: $gray-lighter-2;

$onlineStatusColor: $green;
$offlineStatusColor: $magenta;
$tableContainerBackground: $gray-lighter-2;
$tableBackgroundColor: $gray-lighter-2;
$tableColor: $blue-darker-1;
$tableTopGradient: $gray-lighter-1;
$tableBackgroundColor: $gray-lighter-2;
$tableBottomGradient: $gray;
$tableBorderColor: $gray-lighter-2;

$freeMemberButtonColor: $gray-darker-3;
$freeMemberButtonGradientColor1: $gray-darker-5;
$freeMemberButtonGradientColor2: $gray-darker-4;
$freeMemberButtonGradientColor3: $gray-darker-6;
$freeMemberButtonGradientColor4: $gray-darker-7;

$goldMemberButtonColor: $black;
$goldMemberButtonGradientColor1: $yellow-lighter-2;
$goldMemberButtonGradientColor2: $yellow-lighter-2;
$goldMemberButtonGradientColor3: $orange;
$goldMemberButtonGradientColor4: $orange-darker-1;

$goldTourColor: $blue-lighter-1;
$subscribeColor: $yellow;
$youtubeBackgroundColor: $white;

$searchPlayerButtonColor: $white;
$searchPlayerButtonBackgroundColor: $blue-lighter-1;
$rankingContentColor: $blue;
$rankingContentBackgroundColor: $gray-lighter-2;
$tableCellWinnerTopGradientColor: $gray-lighter-4;
$tableCellWinnerBottomGradientColor: $gray-lighter-5;
$analysisContentBackgroundColor: $gray-lighter-2;
$analysisListRowHeaderBackgroundColor: $gray-lighter-3;
$analysisListRowContentBackgroundColor: $white-lighter-1;
$analysisEmptyContentColor: $blue-darker-1;
$analysisButtonBorderColor: $blue-lighter-1;
$playerInfoBorderBottom: $white;
$playerInfoWinnerBackgroundColor: $gray-lighter-1;
$scoreWinnerColor: $white-lighter-1;
$scoreWinnerColorBackground: $blue;
$scoreLoserColor: $blue;
$scoreLoserBackgroundColor: $white-lighter-1;
$followButtonColor: $white;
$followButtonBackgroundColor: $blue-lighter-1;

$followerRowListBackgroundColor: $white-lighter-1;
$followerRowListBorderColor: $gray-lighter-3;
$followerEmptyContentColor: $blue-darker-1;

$rankingTopCoinsColor: $subscribeColor;
$rankingTopCoinsBackgroundColor: $purple-darker-4;
$rankingTopSeparatorColor: $gray-lighter-3;
$rankingTopGetCoinsColor: $white;
$rankingTopGetCoinsBackgoundColor: $blue-lighter-1;
$rankingTopTextColor: $blue;
$rankingTopGradientColor: $gray-lighter-1;
$rankingsContentColor: $blue-darker-1;
$rankingsChipActiveColor: $white;
$rankingsChipActiveBackgroundColor: $blue;

$outerBoardBackgroundColor: $purple-darker-1-board;
$innerBoardBackgroundColor: $blue-darker-2-board;
$backgroundTopGradient: $purple-darker-1-board;
$backgroundBottomGradient: $purple-lighter-1-board;

$trayColor: $purple-darker-1-board;
$trayCheckerTextColor: $white-board;
$playerTrayBackgroundColor: $blue-darker-4-board;

$playerScoreBackgroundColor: $white-board;
$playerScoreColor: $purple-darker-1-board;
$doublingCubeBackgroundColor: $blue-darker-3-board;
$doublingCubePulsatingBackgroundColor: $purple-board;
$doublingCubeColor: $white-board;

$barColor: $purple-darker-1-board;
$pipCountColor: $white-board;
$playerAColor: $white-board;
$playerBColor: $purple-board;
$playerBBorderColor: $white;

$commandTextBackgroundColor: $blue-darker-3-board;
$commandTextColor: $white-board;

$reviewMatchButtonActiveBackgroundColor: $blue-lighter-1;
$reviewMatchButtonBackgroundColor: transparent;
$reviewMatchButtonDisabledBackgroundColor: desaturate($blue-darker-4, 25%);
$reviewMatchButtonTextColor: $gray-lighter-3;
$reviewMatchHeaderBorderColor: $blue-lighter-2;
$checkerPlayerA: $gray-lighter-3;
$checkerPlayerB: $blue;
$reviewMatchLabelColor: $blue-lighter-2;
$reviewMatchTextColor: $gray-lighter-3;
$reviewMatchBackgroundTopGradient: $purple-darker-3;
$reviewMatchBackgroundBottomGradient: $purple-lighter-2;
$reviewMatchSelectBackgroundColor: $gray-lighter-3;
$reviewMatchSelectTextColor: $purple-darker-3;
$reviewMatchHighlightMovementBackgroundColor: $blue-lighter-2;
$reviewMatchHoverMovementBackgroundColor: rgba($blue-lighter-2, 0.3);
$reviewMatchTitleColor: $blue-lighter-2;
$matchResultStatBackgroundColor: $blue-lighter-2;
$matchResultStatColor: $white;
$matchResultPlayerName: $white;
$matchResultHeadingColor: $white;
$moveDescriptorBackgroundColor: $purple-darker-1-board;
$moveDescriptorColor: $white-board;

$serverNotification: $blue;

$membershipTextColor: $white-lighter-1;

$paginationSelectedPageBackgroundColor: $blue;
$paginationSelectedPageColor: transparent;
$paginationTextColor: $blue;
$paginationTextSelectedColor: $white;
$underConstructionTextColor: $white;

$cookiePopupBackgroundColor: transparentize($purple-darker-3, 0.1);
$cookiePopupButtonBackgroundColor: $blue-lighter-3;
$cookiePopupTextColor: $white-lighter-1;
$cookiePopupBackdropColor: $white;

$legalPageBackgroundColor: $gray-lighter-2;
$legalContainerBackgroundColor: $white-lighter-1;
$legalTitleTextColor: $blue;
$legalCategoryTitleColor: $blue;
$disabledColor: $gray-darker-2;
$facebookTextLinkColor: $white-lighter-1;

$forgotPasswordHeaderColor: $white;
$forgotPasswordLabelColor: $white;
$forgotPasswordButtonColor: $white;
$forgotPasswordLabelColor: $white;
$forgotPasswordButtonBackgroundColor: $blue-lighter-1;
$forgotPasswordLinkTextColor: $purple-lighter-1;
$forgotPasswordSuccessMessageColor: $white;
$forgotPasswordErrorTextColor: $white;
$forgotPasswordErrorBorderColor: $red-lighter-1;

$formContainerBackgroundColor: $white-lighter-1;
$membershipText: $white-lighter-1;
$accountLabelLinkColor: $blue-lighter-1;
$goldMembershipBackground: $yellow-lighter-1;
$accountLabelLinkBackgroundColor: transparent;

$selectedAvatar: $blue-lighter-1;
$customAvatarText: $blue-lighter-1;
$avatarContainerBackgroundColor: $white-lighter-1;

$saveButtonColor: $white;
$saveButtonColorBackground: $blue-lighter-1;
$saveSuccessfulTextColor: $gray-darker-2;
$saveErrorTextColor: $red-lighter-2;

$signupTextSectionBackgroundColor: $blue-darker-3-board;
$signupCaretDesktopBackgroundColor: $white;
$signupCaretMobileBackgroundColor: $blue-lighter-1;

$singUpInputTextColor: $purple-darker-1;

$interactiveTutorColor: $white;
$interactiveTutorBubbleBackground: $blue;

$storePriceBeforeBackgroundOpacity: $purple-opacity;
$storePriceBeforeBackground: $white;
$storeTextColor: $blue;
$storeLineColor: $red-opacity;
$storeDescriptionTextColor: $white;
$storeDescriptionBackgroundColor: $blue-lighter-2;
$storeSelectedOptionBackgroundColor: $white;
$storeSelectedOptionTextColor: $blue;
$storeOptionTextColor: $white;
$storeMembershipBackgroundColor: $blue-lighter-4;
$storeTitleBackgroundColor: $white;
$storeTitleTextColor: $blue;
$storeRowBorderColor: $blue-darker-4;
$storeSubscribeTextColor: $white;
$storePriceTextColor: $blue-lighter-1;

$emailverificationBackgroundColor: $gray-lighter-2;
$emailVerificationBackgroundColor: $gray-lighter-2;
$emailVerificationLinkColor: $purple-lighter-1;
$emailVerificationTextColor: $blue-darker-1;

$blueSpinnerText: $blue;
$whiteSpinnerText: $white;

$loginTitleTextColor: $white;
$loginLabelTextColor: $white;
$signUpOverlayColor: rgba(46, 44, 173, 0.25);

$tournamentDetailJoinButtonColor: $white;
$tournamentDetailJoinButtonBackgroundColor: $blue-lighter-1;
$tournamentDetailLeaveButtonColor: $white;
$tournamentDetailLeaveButtonBackgroundColor: $orange-lighter-1;
$tournamentDetailPlayingStatusColor: $green;
$tournamentDetailFinishedStatusColor: $blue-lighter-1;
$tournamentDetailCustomRadioSelectedBackgroundColor: $black;
$tournamentDetailCustomRadioBackgroundColor: $gray-darker-8;
$tournamentDetailCustomRadioColor: $white-lighter-1;
$tournamentDetailHeaderBannerPrimaryBackgroundColor: $blue-lighter-1;
$tournamentDetailHeaderBannerColor: $white-lighter-1;
$tournamentDetailMatchRowViewButtonColor: $blue-lighter-1;
$tournamentDetailMatchRowViewButtonBorderColor: $blue-lighter-1;
$tournamentDetailMatchRowReconnectButtonColor: $white;
$tournamentDetailMatchRowReconnectButtonBackgroundColor: $blue-lighter-1;

$tournamentOpenButtonColor: $white-lighter-1;
$tournamentOpenButtonBackgroundColor: $blue-lighter-1;
$tournamentRowListContentBackgroundColor: $white-lighter-1;
$tournamentRowListHeaderBackgroundColor: $gray-lighter-3;
$tournamentRowListButtonColor: $white-lighter-1;
$tournamentRowListButtonBackgroundColor: $blue-lighter-1;
$tournamentDisclosureColor: $blue-darker-1;
$tournamentRowEvenBackgroundColor: #e1e2f5;
$tournamentRowOddBackgroundColor: #f2f5ff;
$tournamentRowUserEnrolledBackgroundColor: #4cb96c;

$tournamentModalBackgroundColor: $purple-darker-3;
$tournamentModalHeadingColor: $white-lighter-1;
$tournamentModalButtonColor: $white-lighter-1;
$tournamentModalButtonBackgroundColor: $blue-lighter-1;
$tournamentModalSubTextColor: $purple-lighter-1;

$headerMobileCoinsColor: $subscribeColor;
$headerMobileGetCoinsColor: $white;
$headerMobileGetCoinsBackgoundColor: $blue-lighter-1;

$tournamentPlayersRankingGold: #f5b800;
$tournamentPlayersRankingSilver: #afb3c2;
$tournamentPlayersRankingBronze: #b89979;

$tournamentBracketsNoResultColor: $blue-darker-1;
$tournamentBracketPlayerColor: $blue;
$tournamentBracketPlayerBackgroundColor: $gray-lighter-3;
$tournamentBracketPlayerUserBackgroundColor: #b8e0e0;

$playerOutModalBackgroundTop: $purple-darker-2;
$playerOutModalBackgroundBottom: $purple-darker-1;
$playerOutModalItemBackground: transparentize($purple-lighter-3, 0.4);
$playerOutModalShareButtonBackground: $blue-lighter-1;

$popupModalBackgroundTop: $purple-darker-2;
$popupModalBackgroundBottom: $purple-darker-1;
$popuptModalItemBackground: transparentize($purple-lighter-3, 0.4);
$popupModalShareButtonBackground: $blue-lighter-1;

$arrowBorderColor: $black;
$arrowBaseFillColor: $black;

$optimalMoveArrowColor: #39ed90;
$almostOptimalMoveArrowColor: #78aaf9;
$errorMoveArrowColor: #ffe300;
$blunderMoveArrowColor: #ff244f;
