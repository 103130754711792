@import 'globalStyles/colors';
@import 'globalStyles/mixins/svg-text';

@mixin text-checker($color) {
  @include svg-text(0.786rem, $color);
  pointer-events: none;
}

.textPlayerAChecker {
  @include text-checker($playerBColor);
}

.textPlayerBChecker {
  @include text-checker($playerAColor);
}
