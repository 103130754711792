@import 'globalStyles/colors';
@import 'globalStyles/mixins/svg-text';

.player-tray {
  fill: $playerTrayBackgroundColor;
}

.text-checker {
  @include svg-text(0.7rem, $trayCheckerTextColor);
}
